export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const ISSUANCE_RATE_MONTH = process.env.ISSUANCE_RATE_MONTH || 720; // Time circles
export const PATHFINDER_HOPS_DEFAULT = 3;
export const NEEDED_TRUST_CONNECTIONS = 3;

export const ABOUT_URL = 'https://joincircles.net';
export const BUG_REPORTING_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSdczjck3RFE5VN5oSuge6aQWVAY7WA5RyIRYyFusj1kCSz0SQ/viewform';
export const EMAIL_URL = 'mailto:hello@joincircles.net';
export const FACEBOOK_URL = 'https://facebook.com/CirclesUBI';
export const FAQ_URL = 'https://joincircles.net/faq';
export const FAQ_URL_DELETION =
  'https://joincircles.net/faq/profile-data-deletion/';
export const FAQ_TRANSITIVE_TRANSFERS_URL =
  'https://joincircles.net/faq/who-can-accept-circles-tokens-crc-as-a-payment/';
export const MARKETPLACE_URL = 'http://market.joincircles.net';
export const PRIVACY_LEGAL_URL = 'https://joincircles.net/privacy-policy';
export const TELEGRAM_URL = 'https://t.me/CirclesUBI';
export const TERMS_URL = 'https://joincircles.net/terms';
export const TWITTER_URL = 'https://twitter.com/CirclesUBI';

export const SAFE_LAST_VERSION = '1.3.0';
export const SAFE_CRC_VERSION = '1.1.1';

export const FILTER_TRANSACTION_ALL = 'FILTER_TRANSACTION_ALL';
export const FILTER_TRANSACTION_SENT = 'FILTER_TRANSACTION_SENT';
export const FILTER_TRANSACTION_RECEIVED = 'FILTER_TRANSACTION_RECEIVED';
